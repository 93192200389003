.mainDiv{
    /* width: 100vw; */
    
}

.pageContainer{
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 100px;
    /* height: 100vh; */
    /* position: absolute; */
    /* overflow: auto; */
}

.pageTitle{
    color: #ffffff;
    margin: 20px 0px;
    font-size: 2em;
}

.flex-container-row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.flex-container-col{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.standardButton{
    background-color: rgb(57,178,211);
    color: white;
    width: 100%;
    font-weight: 600;
    font-size: 1em;
    padding: 10px;
    cursor: pointer;
    border:none;
    outline: none;
    border-radius: 3;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .3);
    border-radius: 3px;
}
.standardButton:hover{
    background-color: rgb(88,219,136);
}

.flex-row-spaced{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.pulseImg{
    animation: Pulsate 2s linear infinite;
  }

  #aboutUsImg{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('../static/about_us_img.jpg');
    min-height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: 40% 40%;
  }

  .flex-responsive-row{
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;
      flex-wrap: nowrap;
      align-items: center
  }

  #ufAtHomeImg{    
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('../static/chicago_gym.jpg');
    min-height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: 0% 20%;
}


  
#NinjaNearYouImg{    
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('../static/drew_boards.jpg');
    min-height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: 0% 20%;
}

.unxBtn{
    background-color: rgb(57,178,211);
    color: white;
    width: 100%;
    font-weight: 600;
    font-size: 1em;
    padding: 10px;
    cursor: pointer;
    border:none;
    outline: none;
    border-radius: 3;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .3);
    border-radius: 3px;
}

.unxBtn:hover{
    background-color: rgb(88,219,136);
}
  
  @keyframes Pulsate {
    from { opacity: 1; }
    50% { opacity: 0; }
    to { opacity: 1; }
  }


  @media only screen and (max-width: 500px)  {
    .flex-responsive-row{
        flex-wrap: wrap;
    }
  }

  @media only screen and (max-width: 750px)  {
    .tabletWrap{
        display: block;
    }
  }