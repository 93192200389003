
  h1 {
    color: #32325d;
    font-weight: 400;
    line-height: 50px;
    font-size: 40px;
    margin: 20px 0;
    padding: 0;
  }

  /* .Checkout {
    margin: 0 auto;
    max-width: 800px;
    box-sizing: border-box;
    padding: 0 5px;
  } */

  label {
    color: #6b7c93;
    font-weight: 300;
    letter-spacing: 0.025em;
  }

  .stripeButton {
    white-space: nowrap;
    border: 0;
    outline: 0;
    display: inline-block;
    height: 40px;
    /* line-height: 40px; */
    padding: 0 14px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08);
    color: #fff;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025em;
    /* background-color: #6772e5; */
    background-color: rgb(77,198,231);
    text-decoration: none;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
    width: 100%;
  }

  /* .stripeForm {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 3px solid #e6ebf1;
  } */

  /* button:hover {
    color: #fff;
    cursor: pointer;
    background-color: #7795f8;
    transform: translateY(-1px);
    box-shadow: 0 7px 14px rgba(50, 50, 93, .10), 0 3px 6px rgba(0, 0, 0, .08);
  } */

  .stripeInput,
  .StripeElement {
    display: block;
    margin: 10px 0 20px 0;
    max-width: 500px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  .stripeInput::placeholder {
    color: #aab7c4;
  }

  .stripeInput:focus,
  .StripeElement--focus {
    box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }

  .StripeElement.IdealBankElement,
  .StripeElement.PaymentRequestButton {
    padding: 0;
  }

  /* .stripeForm{
    background-color: rgba(255,255,255,0.8);
    padding: 25px;
    border-radius: 3px;
    width: 400px;
  } */

  .subscriptionBox{
    background-color: rgba(255,255,255,0.8);
    padding: 25px;
    border-radius: 3px;
    margin: 10px 10px;
    max-width: 400px;
  }

  .subBoxTitle{
    font-size: 1.8em;
    font-family: 'VIPESSRG';
  }

  .listItem{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .listBox{
    border: 1px solid rgb(175,175,175);
    border-radius: 3px;
    margin: 10px 0px;
  }

  .subBoxPrice{
    font-size: 1.5em;
    padding: .25em;
    text-align: center;
    /* background: rgb(88,219,136); */
    border: 3px solid rgb(48,180,96);
    border-radius: 3px;
    color: rgb(48,180,96);
    font-weight: bold;
    margin: 10px 0px;
  }

  .footerText{
    margin: 10px 0px;
    color: rgb(115,115,115);
  }

  .defaultPayCont{
    padding: 10px;
    border: 1px solid rgb(175,175,175);
    border-radius: 3px;
    margin: 5px 0px;
  }

  .coupInput {
    display: block;
    margin: 8px 0px;
    width: 300px;
    padding: 10px 14px;
    font-size: 1em;
    font-family: 'Source Code Pro', monospace;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px, rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  .coupMatch{
    box-shadow: 0px 0px 5px 2px rgb(103,235,127);
}

.coupMissMatch{
    box-shadow: 0px 0px 5px 2px rgb(235,103,103);
}