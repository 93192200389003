.flex-account-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin: 25px 0px;
}

.accountTitle{
    color: rgb(255,255,255);
    font-size: 2em;
}

.acctContainerTitle{
    font-size: 1.2em;
    color: #ffffff;
    border-radius: 3px 3px 0px 0px;
    background-color: rgb(57,178,211);
    padding: 5px;
}

.acctContainerCol{
    background-color: rgb(20,24,32);
    border-radius: 0px 0px 3px 3px;
    /* margin-top: 10px; */
    /* overflow-y: auto; */
    /* height: 0px; */
    display: flex;
    flex-direction: column;
    padding: 20px;
    color: rgb(200,200,200);
}

.addPaymentSource{
    color: rgb(50,50,50);
    background-color: rgba(255,255,255,0.9);
    border-radius: 3px;
    margin: 10px 0px;
    /* padding: 10px; */
    max-height: 0px;
    transition: max-height 0.5s;
    overflow: hidden;
}

.showPaymentSource{
    max-height: 300px;
  transition: max-height 0.5s;
}

.spanLink{
    margin: 5px;
    color: rgb(57,178,211);
    text-decoration: underline;
    cursor: pointer;
}


@media only screen and (max-width: 767px)  {

    .flex-account-main{
        flex-wrap: wrap;
    }

}