.homeDiv{
    background-color: rgb(240,240,240);
}


.flex-center-center{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
}

.fullscreenVideo{
    position: relative;
    width: 90%;
    max-height: 600px;
    overflow: hidden;
    text-align: center;
}

.videoMask{
    position: absolute;
    background-color: rgb(0,0,0,0);
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 5;
}

#mainVideo{
    object-fit: contain;
}

.videoTitle{
    color: #ffffff;
    font-size: 2.5em;
    font-weight: bold;
}

.appHighlights{
    background-color: rgb(20,24,32);
    margin: 10px 0px;
    padding: 10px;
    border-radius: 2px;
}

.sectionTitle{
    color: #ffffff;
    font-size: 2em;
    font-weight: bold;
}

.sectionDesc{
    color: rgb(205,205,205);
    font-size: 1em;
    /* font-weight: bold; */
}

.videoButton{
    background-color: rgb(57,178,211);
    color: white;
    width: 100%;
    margin-top: 25px;
    font-weight: 600;
    font-size: 1.2em;
    padding: .75em;
    cursor: pointer;
    border:none;
    outline: none;
    border-radius: 3;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, .3);
    border-radius: 3px;
}
.videoButton:hover{
    background-color: rgb(88,219,136);
}

.homeContent{
    width: 90%;
}

.homeIcon{
    color: #ffffff;
    font-size: 2.5em;
    text-align: center;
}

.iconText{
    text-align: center;
}

.card-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
  }
  
  .cardTitle{
    /* font-family: VIPESSRG; */
    font-size: 2em;
    color: #ffffff;
  }
  
  .cardDetail{
    font-size: 1em;
    color: rgb(205,205,205);
  }
  
  .cardImg{
      width: 300px;
      margin: 5px;
    }

.container {
    position: relative;
    text-align: center;
    color: white;
  }

  #newsContainer{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../static/digangi_pic.jpg');
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }

  .textOverlayMask{
    background: rgba(0,0,0,0.7);
    color: rgb(255,255,255);
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100%;
    bottom: 0px;
  }

  .textOverlayContainer{
    padding: 10px;
  }
  .textOverlayTitle{
    font-size: 1.3em;
    font-weight: bold;
  }
  .textOverlayDesc{

  }

  .standingsLink{
    font-size: .5em;
    color: rgb(57,177,211);
    cursor: pointer;
  }

  #ufAtHome{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('https://unx-now-main-storage.s3.amazonaws.com/images/ultiFIT_At_Home/unx_bar_system_v1.jpg');
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: 0% 30%;
  }

  #majorQualifiers{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../static/naperville_gym.jpg');
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: 0% 30%;
    
  }

  .whiteCard{
    background-color: #ffffff;
    border: #979797;
    /* cursor: pointer; */
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    font-size: 1.5em;
    min-height: 100%;
}

.whiteCardContainer{
    padding: 10px;
    /* position: relative;
    height: 100%;*/
    overflow: hidden; 
}

.whiteCardTitle{
    font-family: "VIPESSRG"; 
    /* color: rgb(20,24,32);  */
    color: rgb(255,255,255);
    font-size: .8em; 
    letter-spacing: .5px;
    /* background-color: rgb(60,64,72);*/
    background-color: rgb(60,64,72);
    padding: 10px;
    /* border-bottom: 1px solid rgb(255,255,25); */
}

.whiteCardText{
    color: rgb(40,44,52);
    font-size: .6em;
    margin: 10px;
}

.standingsText{
    max-height: 280px;
    overflow-y: scroll;
}
.standingsItem{
    color: rgb(40,44,52);
    font-size: .6em;
    padding: 10px;
    border-bottom: 1px solid rgb(235,235,235);
}

  .overlay{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6), rgba(0,0,0,0.9));
  }

  .blueOverlay{
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    /* background: linear-gradient(rgba(77,198,231, .2), rgba(77,198,231, .4), rgba(77,198,231, .9)); */
    background: linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.6), rgba(0,0,0,0.9));
  }
  
  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 0px;
    left: 0px;
    text-align: center;
    width: 100%;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 8px;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 8px;
    right: 16px;
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .imgTitle{
    color: #ffffff;
    font-size: 2em;
    font-weight: bold;
}

.wrapText{
    position: relative;
    padding: 10px;
}

.flex-offer-row{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.flexFix{
    margin: 10px; 
    flex-grow: 1; 
    flex-basis: 0;
}


.collapse{
    max-height: 0px;
    transition: max-height 0.5s;
    overflow: hidden;
}

.activeCollapse{
    max-height: 1000px;
  transition: max-height 0.5s;
}

.expandBtn{
    background-color: rgb(200,200,200);
    text-align: center;
    border-radius: 0px 0px 3px 3px;
    margin: 0px -25px -25px -25px;
    padding: 15px;
    cursor: pointer;
}

.calendarContainer{
    margin: 20px;
}
.calendarTitle{
    font-size: 2em;
    color: rgb(200,200,200);
}
.calendarRowTitle{
    font-size: 1.5em;
    color: rgb(200,200,200);
}
.dayTitle{
    color: rgb(200,200,200);
    margin: 20px;
    font-weight: bold;
    font-size: 1.3em;
}
.calendarCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: 10px;
    /* border-left: 1px solid rgb(60,64,72);
    border-right: 1px solid rgb(60,64,72); */
}
.calendarRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}
.calendarItemRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.calendarItemImg{
    text-align: center;
}
.calendarItem{
    background-color: rgb(20,24,32);
    color: rgb(175,175,175);
    padding: 20px;
    border-radius: 4px;
    margin: 5px;
}

.calendarItemText{
    padding: 5px;
}

.flex-home-main{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
}

.flexTwo{
    flex: 2;
}

.flexOne{
    flex: 1;
}

@media only screen and (max-width: 900px)  {
    .flex-home-main{
        display: block;
    }
    #newsContainer{
        min-height: 250px;
    }
    #ufAtHome{
        min-height: 300px;
    }
}

@media only screen and (max-width: 500px)  {
    .fullscreenVideo{
        width: 100vw;
    }
    .homeContent{
        width: 100vw;
    }
    .cardImg{
        width: 100vw;
        margin: 10px 0px;
      }
      .flex-offer-row{
          flex-wrap: wrap;
      }
      .flexFix{
        flex-basis: auto;
    }

    /* .flexTwo{
        flex: 0;
    }
    
    .flexOne{
        flex: 0;
    } */

    #actionPhoto{
        height: 400px;
        object-fit: cover;
    }
    
    
    }
@media only screen and (min-width: 550px){
    #actionPhoto{
        height: 400px;
        object-fit: cover;
    }
    #gymPhoto{
        height: 500px;
        object-fit: cover;
    }

}