.App {
    text-align: center;
    background-color: #282c34;
  }
  
  .App-logo {
    height: 20vmin;
    pointer-events: none;
    margin: 25px;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: white;
    padding: 20px 40px 100px 40px;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  .listIcon{
    color:#61dafb;
    margin: 10px;
  }
  .listItem{
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: start;
  }
  .listText{
    font-size: 20px;
  }
  
  .flex-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    /* flex-wrap: wrap; */
  }
  
  .flex-container2{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    /* flex-wrap: wrap; */
  }
  
  .qualBox{
    background-color: rgba(255,255,255,0.8);
    border-radius: 3px;
    justify-content: center;
    margin: 3px;
    padding: 10px;
  }
  .qualTitle{
    color: #282c34;
    font-size: 20px;
  }
  .qualDesc{
    font-size: 16px;
  }
  
  .majorBox{
    background-image: radial-gradient(circle, #a8e9fa, #94e5fb, #7fe0fc, #65dcfd, #44d7ff);
      border-radius: 3px;
      justify-content: center;
      margin: 10px;
      padding: 10px;
  }
  
  .exBorder{
    margin-top: 50px;
    border: 3px solid #61dafb;
    border-style: dashed;
    padding: 10px 20px;
  }
  
  .exTitle{
    background-color: #282c34;
    color: #ffffff;
    margin-top: -25px;
    margin-bottom: 20px;
    display:table;
    padding: 0px 10px;  
  }
  
  .championshipBox{
    background-image: radial-gradient(circle, #262728, #1f1f20, #171718, #0e0e0e, #000000);
    border-radius: 3px;
      justify-content: center;
      margin: 10px;
      padding: 10px;
  }
  
  .standingsBox{
    background-image: radial-gradient(circle, #00488e, #014181, #023b74, #033467, #042e5b);
    border-radius: 3px;
      justify-content: center;
      margin: 10px;
      padding: 10px;
  }
  
  .unxCompOverview{
    flex: 1;
    border-right: 1px solid rgb(80,84,90);
    margin: 10px;
  }
  
  .unxNetOverview{
    flex: 1;
    margin: 10px;
  }
  
  
  @media only screen and (max-width: 825px)  {
    .qualTitle{
      font-size: 12px;
    }
    .flex-container{
      flex-wrap: wrap;
    }
    .unxCompOverview{
      border-right: 0px solid rgb(80,84,90);
    }
  }