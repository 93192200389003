.flex-dash-main{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.darkContainerCol{
  background-color: rgb(20,24,32);
  flex-grow: 1;
  border-radius: 3px;
  margin-top: 10px;
  overflow-y: auto;
  height: 0px;
  display: flex;
  flex-direction: column;
}

.darkContainerRow{
  background-color: rgb(20,24,32);
  flex-grow: 1;
  border-radius: 3px;
  margin-top: 10px;
  padding: 5px;
}

.darkContainerTitle{
  font-size: 1em;
  font-weight: bold;
  color: rgb(225,225,225);
}

.searchVideos{
  flex: 1;
  margin: 2px 10px;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
}

.mainVideoContainer{
  flex: 2;
  /* overflow: hidden;
  padding-top: 56.25%;
  position: relative; */
  display: flex;
  overflow: hidden;
  max-width: 1000px;
}

.mainVideo{
  /* border: 0;
 height: 100%;
 left: 0;
 position: absolute;
 top: 0;
 width: 100%; */
 flex-grow: 1;
}

.acDiv{
  display: flex;
}

.acInput {
  border: 1px solid rgb(55,59,67);
  padding: 1em;
  color: rgb(175,175,175);
  background-color: rgb(20,24,32);
  font-size: 1em;
  flex-grow: 1;
}

.no-suggestions {
  color: #999;
  padding: 0.5rem;
}

.suggestionDiv{
    position: relative;
    display: flex;
}

.suggestions {
  border: 1px solid rgb(55,59,67);
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  width: 100%;
  color: rgb(175,175,175);
  background-color: rgb(40,44,52);
}

.suggestions li {
  padding: 0.5rem;
}

.suggestions li:hover {
  background-color: rgb(20,24,32);
  color: rgb(175,175,175);
  cursor: pointer;
  font-weight: 700;
}

.suggestions li:not(:last-of-type) {
  border-bottom: 1px solid rgb(55,59,67);
}

.videoSummaryChoice{
    padding: 10px;
    border-bottom:1px solid rgb(60,64,72);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: auto;
  cursor: pointer;
  flex-grow: 1;
  align-items: center;
}

.videoSummaryChoice:hover{
    background-color: rgb(60,64,72);
}

.roundedSummaryImage{
    border-radius: 4px;
}

.videoSummmaryTitles{
  color: rgb(255,255,255);
  font-size: 1.5em;
}

.videoSummmarySubTitles{
  color: rgb(175,175,175);
}

.videoSummaryMargins{
    margin: 0px 10px;
}

.videoMainChoice{
  padding: 10px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;
  cursor: pointer;
  /* flex-grow: 1; */
  justify-content: flex-start;
  width: calc(25% - 20px);
}

/* .videoMainChoice:hover{
  background-color: rgb(60,64,72);
} */

.roundedMainImage{
  border-radius: 4px;
}

.videoMainTitles{
color: rgb(255,255,255);
font-size: 1.5em;
}

.videoMainSubTitles{
color: rgb(175,175,175);
}

.videoMainMargins{
  /* margin: 0px 10px; */
}


.aspect-ratio-box {
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
  background: white;
  position: relative;
  /* background: url('../static/digangi_pic.jpg'); */
  background-size: cover;
  width: 100%;
  border-radius: 7px;
}

.aspect-ratio-box-inside {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  color: rgba(200,200,200,0.9);
  opacity: 0;
}

.aspect-ratio-box:hover .aspect-ratio-box-inside{
    opacity: 1;
    transition: .3s ease;
}

.aspect-ratio-box-inside-locked {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  color: rgba(200,200,200,0.9);
  opacity: 1;
}

.flexbox-centering {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.viewport-sizing {
  font-size: 5vw;
  text-align: center;
}

.imgMainContainer{
    width: 100%;
}

.comingSoonText{
  font-size: .3em;
}


/* .image-wrapper {
  position: relative;
  padding-bottom: 56.25%;
}

.image-wrapper img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
} */

.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    width: 100%; 
  } 
    
.embed-container iframe, .embed-container object, .embed-container embed { 
  position: absolute; 
  top: 0; 
  left: 0; 
  width: 100%; 
  height: 100%; 
  z-index: 1;
}

.pulseImg{
animation: Pulsate 2s linear infinite;
}

#trendingMobile{
display: none;
}


@keyframes Pulsate {
from { opacity: 1; }
50% { opacity: 0; }
to { opacity: 1; }
}

@media only screen and (max-width: 825px)  {
      .searchVideos{
          display: none;
      }
      #trendingMobile{
        display: flex;
      }
  }

  @media only screen and (max-width: 1000px)  {
      .roundedSummaryImage{
          width: 50px;
      }
      .videoSummmaryTitles{
          font-size: 1em;
      }
      .videoSummmarySubTitles{
          font-size: .75em;
      }
  }

  @media only screen and (max-width: 767px)  {
      
      .videoMainChoice{
          width: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
          border-bottom:1px solid rgb(60,64,72);
      }
      .videoMainMargins{
          flex: 2;
          margin: 0px 10px;
      }
      .imgMainContainer{
          flex: 1.5;
      }
      .videoMainTitles{
          font-size: 4vw;
        }
        
        .videoMainSubTitles{
          font-size: 3vw;
        }

  }

  @media only screen and (max-width: 1023px) and (min-width: 768px)  {
      
      .videoMainChoice{
          width: calc(50% - 20px);
      }
      .videoMainTitles{
          font-size: 3vw;
        }
        
        .videoMainSubTitles{
          font-size: 5vw;
        }
        
        .videoMainSubTitles{
          font-size: 2vw;
        }
  }