.selectBox{
    background-color: #3d3e3f;
    color: #ffffff;
    font-size: 16px;
    padding: 5px;
    border-radius: 3px;
    cursor: pointer;
    margin: 10px;
}

.lbTitle{
    color: rgb(255,255,255);
    font-size: 2em;
}

.subTitle{
    font-size: 24px;
    margin-top: 20px;
    color: rgb(200,200,200);
}

.activeBox{
    background-color: rgba(0,90,180,0.5);
    color: #ffffff;
}

.athleteBox{
    background-color: rgb(60,64,72);
    color: rgb(255,255,255);
    border: #979797;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin-top: 15px;
    font-size: 1.5em;
}

  .completed{
    background-color: rgb(58,189,106);
    color: #ffffff;
}

.obstacleBox{
    min-width: 300px;
    background-color: #ffffff;
    border: #979797;
    cursor: pointer;
    text-align: center;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    margin-top: 10px;
}
.obstacleBoxTitle{
    font-size: 18px;
    border-bottom: 2px solid #bdbdbd;
    background-color: rgb(200,200,200);
    padding: 5px;
}
.obstacleBoxContent{
    font-size: 24px;
    padding: 10px;
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 3px;
    cursor: pointer;
}
.obstacleBoxContent:hover{
    background-color: rgb(230,230,230);
}


.leaderboardDivision{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.athleteDivision{
    flex-grow: 1;
    margin: 0px 10px;
}

.scrollLink{
    text-align: center;
    padding: 10px;
    display: none;
    color: rgb(0,90,180);
    cursor: pointer;
    font-size: 1.2em;
    text-decoration: underline;
}

.tieTime{
    font-size: .8em;
    flex-grow: 1;
}


.hideMobile{
    display: block;
}


@media only screen and (max-width: 600px){
    .leaderboardDivision{
        flex-wrap: wrap;
    }
    .scrollLink{
        display: block;
    }
    .hideMobile{
        display: none;
    }
}
