body{
    margin: 0px;
    font: 14px "Lucida Grande", Helvetica, Arial, sans-serif;
}

.headerSection{
    background-color: rgba(10,10,10,0.8);
    color: #ffffff;
    /* font-size: 32px; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
    /* width: 100vw; */
    padding: 15px;
    /* position: sticky;
    top: 0;
    z-index: 900;  */
}

.headerContainer{
    position: sticky;
    top: 0;
    z-index: 900; 
}

.signInDiv{
    margin-left: auto;
    display: flex;
}

.navBtn{
    color: rgb(0,90,180);
    background-color: rgb(0,0,0,0);
    border: 1px solid rgb(0,90,180);
    cursor: pointer;
    margin-right: 25px;
    padding: .75em;
    border-radius: 3px;
    font-size: 16px;
    font-weight: bold;
}

.navBtn:hover{
    background-color: rgb(0,90,180, .2);
    transition: .3s;
}

.footerSection{
    background-color: rgb(20,24,32);
    color: #ffffff;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    /* height: 100px; */
    padding: 35px;
}

.footerLink{
    color: #ffffff;
    text-decoration: none;
}

.linkDiv{
    margin: 20px;
}

.footerLink:hover{
    color: rgb(57,178,211);
    text-decoration: none;
}

.headerLink{
    text-decoration: none;
    color: rgb(57, 178,211);
    margin: 0px 10px;
    cursor: pointer;
}

.headerLink:hover{
    color: rgb(216, 216, 27);
    transition: .3s ease;
}

.menuItems{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 50px;
}

.menuItemsMobile{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-left: 50px;
    display: none;
}

.menuIcon{
    padding: 5px 15px;
    border: 2px solid rgb(57, 178,211);
    border-radius: 3px;
    font-size: 32px;
    position: relative;
}

.menuDropdown {
    /* display: none; */
    position: absolute;
    background-color: rgba(40,44,52, 1);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    right: 0;
    margin-top: 10px;
    max-height: 0px;
    transition: max-height 0.5s;
    overflow: hidden;
  }

  .dropdownMenuItems{
      padding: 10px;
      z-index: 1001;
  }

  .ddItem{
      font-size: .5em;
      padding: 15px 5px;
      z-index: 1002;
  }

  .showContent{
      max-height: 1000px;
    transition: max-height 0.5s;
  }

  .navDropContain{
      position: relative;
      display: inline-block;
      cursor: pointer;
  }
  .navDropMenu{
    /* display: none; */
    position: absolute;
    background-color: rgb(60,64,72);
    z-index: 1000; 
  }

  /* .navDropContain:hover .navDropMenu {display: block;} */

  .navDropItem{
      padding: 10px 20px;
      cursor: pointer;
      margin: 0px;
  }

  .navDropItem:hover{
      background-color: rgb(80,84,92);
  }

@media only screen and (max-width: 650px)  {
    .menuItemsMobile{
        display: flex;
    }
    .menuItems{
        display: none;
    }
  }
