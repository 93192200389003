#UNXChampImg{    
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.9)), url('../static/promoPoster.png');
    min-height: 300px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    background-position: 30% 30%;
}

.competitionRow{
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
}

/* .scoreboard{
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url('../static/scoreboard_backplate.jpg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 100vh;
    overflow: scroll;
} */

.scoreboard{
    max-height: 1000px;
    overflow: auto;
}

.standingsBox{
background-color: #ffffff;
border: #979797;
cursor: pointer;
padding: 20px;
text-align: center;
box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
transition: 0.3s;
margin-top: 15px;
font-size: 1.5em;
}

.leaderboardDivision{
display: flex;
flex-direction: row;
flex-wrap: nowrap;
margin: 35px;
}

.athleteDivision{
flex-grow: 1;
margin: 0px 10px;
}

.scoreboardTitleBox{
background-image: radial-gradient(circle, #000000, #171416, #242225, #313036, #3c4048);
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
padding: 25px;
}

.scoreboardTitle{
font-size: 2em;
font-weight: bold;
color: #ffffff;
}

.scoreboardSubTitle{
font-size: 1.4em;
color: #ffffff;
}

.scoreboardTable td{
padding: 10px;
}

.tableHeader{
background-color: rgb(10,10,10);
color: #ffffff;
font-size: 1.75em;
}

.tableHeader th{
padding: 10px;
}

.rankCell{
background-color: rgb(234, 238, 15);
color: #000000;
font-weight: bold;
}
.nameCell{
background-color: rgb(0,0,0);
color: #ffffff;
}
.locCell{
background-color: rgb(57,178,211);
color: #000000;
font-weight: bold;
}
.timeCell{
background-color: rgb(234, 238, 15);
color: #000000;
font-weight: bold;
}


@media only screen and (max-width: 600px)  {
    .competitionRow{
        display: block;
    }
}